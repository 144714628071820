// import '@mdi/font/css/materialdesignicons.css'

// import 'vuetify/styles'

// import { createI18n, useI18n } from 'vue-i18n'
import { createVuetify } from 'vuetify'
// import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { useCookie } from '#app'
import '@/assets/css/main.scss'
import theme from '@/plugins/vuetify/theme'

const messages = Object.fromEntries(
    Object.entries(
        import.meta.glob<{ default: any }>('~/locales/*.json', { eager: true })
    ).map(([key, value]) => [key.slice(10, -5), value.default])
)

export default defineNuxtPlugin((nuxtApp) => {
    const localeCookie = useCookie('locale')
    const locale = localeCookie.value || 'en'

    // const i18n = createI18n({
    //     legacy: false,
    //     locale,
    //     fallbackLocale: 'en',
    //     messages: {
    //         en: {
    //             $vuetify: messages.en
    //         }
    //     }
    // })

    const vuetify = createVuetify({
        theme,
        ssr: true,
        icons: {
            defaultSet: 'mdi',
            aliases: {
                dropdown: 'mdi-chevron-down'
            }
        },
        display: {
            thresholds: {
                xs: 0,
                sm: 480,
                md: 768,
                lg: 1025,
                xl: 1440
            }
        },
        // locale: {
        //     adapter: createVueI18nAdapter({ i18n, useI18n })
        // }
    })

    nuxtApp.vueApp.use(vuetify)
})
