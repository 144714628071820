import { defineNuxtPlugin } from '#app'
import type { PluginOptions } from 'vue-toastification'
import Toast from 'vue-toastification'

export default defineNuxtPlugin((nuxtApp) => {
    const options: PluginOptions = {
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
        timeout: 3000,
        pauseOnHover: false,
        draggable: false,
        icon: false
    }

    nuxtApp.vueApp.use(Toast, options)
})
