import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import vue_toastification_gZJw6dQQf2 from "/opt/atlassian/pipelines/agent/build/frontend/plugins/vue-toastification.ts";
import Vue3Marquee_client_r0z0zv1iLW from "/opt/atlassian/pipelines/agent/build/frontend/plugins/Vue3Marquee.client.ts";
import index_kyvreQaQna from "/opt/atlassian/pipelines/agent/build/frontend/plugins/vuetify/index.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  vue_toastification_gZJw6dQQf2,
  Vue3Marquee_client_r0z0zv1iLW,
  index_kyvreQaQna
]