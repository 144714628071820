import { default as impressumfZK1jAwCHYMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/impressum.vue?macro=true";
import { default as indexRnD4wjZ73BMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/index.vue?macro=true";
import { default as privacy_45policyWmkH8zjJgqMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/privacy-policy.vue?macro=true";
import { default as sign_45upWYFqrhklFQMeta } from "/opt/atlassian/pipelines/agent/build/frontend/pages/sign-up.vue?macro=true";
export default [
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/privacy-policy.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    component: () => import("/opt/atlassian/pipelines/agent/build/frontend/pages/sign-up.vue")
  }
]