import type { VuetifyOptions } from 'vuetify';
import 'vuetify/styles';

const theme: VuetifyOptions['theme'] = {
  defaultTheme: 'light',
  themes: {
    light: {
      dark: false,
      colors: {
        white: '#FDFDFD',
        primary: '#02363E',
        secondary: '#204A50',
        'soft-teal': '#8EB8BE',
        'lime-green': '#B5D15E',
        'primary-purple': '#CFB4D4',
        'primary-lavender': '#8887D2',
        'primary-orange': '#FFB55B',
      },
    },
  },
};

export default theme;
